<template>
  <v-progress-circular
    v-if="isLoading"
    indeterminate
    class="ml-4"
    color="primary"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    title: { type: String, required: false, default: '' },
    customLoadingTrigger: { type: Boolean, required: false, default: null }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    isLoading() {
      if (this.customLoadingTrigger !== null) {
        return this.customLoadingTrigger
      }
      return this.dataIsLoading
    }
  }
}
</script>

<style scoped></style>
